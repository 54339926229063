import { FC, useCallback, useState } from 'react';
import Button, { ButtonSize } from '../shared/form-control/Button';
import { DocumentResponse } from '../../models/Document';
import SignatureModal from './SignatureModal';
import { SignatureDetails } from '../../models/ClientForm';
import { ModalContext } from '../../contexts/ModalContext';
import { EventSystem } from '../../events/EventSystem';
import DistributionService from '../../services/DistributionService';
import useFormTypeText from '../../hooks/useFormTypeText';
import { useTranslation } from 'react-i18next';
import { FormType } from '../../models/FormTypes';
import { ToastType, useToasts } from '../../contexts/ToastContext';

type Props = {
  document: DocumentResponse;
  disabled: boolean;
  disabledTooltip?: string;
  text?: string;
  className?: string;
  containerClassName?: string;
  size?: ButtonSize;
  requiresAuth?: boolean;
  signerName?: string;
};

const AcknowledgeButton: FC<Props> = (props) => {
  const { document, disabled, text, className, containerClassName, size = ButtonSize.S, requiresAuth = true, signerName, disabledTooltip } = props;
  const { t } = useTranslation(['common', 'distribution']);
  const formTypeText = useFormTypeText(FormType.Document, t);
  const toasts = useToasts();

  const [signModalOpen, setSignModalOpen] = useState(false);
  const [reason, setReason] = useState('');
  const [isBusy, setBusy] = useState(false);

  const onSignModalClose = useCallback(() => {
    setSignModalOpen(false);
    setReason('');
  }, []);

  const onAcknowledge = useCallback(
    (signatureDetails?: SignatureDetails) => {
      setBusy(true);
      return DistributionService.acknowledge(document.id, document.distribution.id, reason, signatureDetails)
        .then((res) => {
          if (!res.data.success) {
            if (res.data.redirectUrl) {
              window.location = decodeURI(res.data.redirectUrl) as string & Location;
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return new Promise(() => {}) as any;
          }

          EventSystem.fireEvent('acknowledged', { id: document.id, signed: !!signatureDetails });
          onSignModalClose();
          toasts.addToast({
            title: t('form-header.toasts.acknowledged.title', { type: formTypeText }),
            type: ToastType.SUCCESS,
            description: t('form-header.toasts.acknowledged.text', { type: formTypeText }),
            expiresInMs: 5000,
          });
        })
        .catch((err) => {
          toasts.addToast({
            title: t('form-header.toasts.acknowledged.failed', { type: formTypeText }),
            type: ToastType.ERROR,
            description: err?.data?.meta?.message,
            expiresInMs: 5000,
          });
        })
        .finally(() => {
          setBusy(false);
        });
    },
    [document.distribution.id, document.id, formTypeText, onSignModalClose, reason, t, toasts],
  );

  const onClick = useCallback(() => {
    if (document.distribution.settings.enableAcknowledgementSignature) {
      setSignModalOpen(true);
    } else {
      onAcknowledge();
    }
  }, [document.distribution.settings.enableAcknowledgementSignature, onAcknowledge]);

  return (
    <>
      <Button
        size={size}
        title={!disabled ? undefined : disabledTooltip || t('distribution:acknowledge.tooltip')}
        disabled={disabled}
        onClick={onClick}
        className={className}
        containerClassName={containerClassName}
        loading={isBusy}
      >
        {text ? text : t('distribution:acknowledge.button')}
      </Button>

      <ModalContext.Provider value={{ open: signModalOpen, modalWidth: 'w-full sm:w-4/5 md:w-3/5 lg:w-1/2 xl:w-[800px]', onClose: onSignModalClose }}>
        <SignatureModal
          clientForm={document}
          mode="acknowledgement"
          reason={reason}
          onReasonChange={(e) => setReason(e.target.value)}
          onSign={onAcknowledge}
          requiresAuth={requiresAuth}
          signerName={signerName}
        />
      </ModalContext.Provider>
    </>
  );
};

export default AcknowledgeButton;
